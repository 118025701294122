import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { GridEntry, Operations } from 'state-domains/domain/subscription';
import { buildCoordinatesUrl } from 'state-domains/network';
import { convertToCamel, convertToSnake } from 'state-domains/utils/typeCast';

import { sendDeleteRequestWithXSRFToken, sendRequestWithXSRFToken } from '../../../utils';

export const createUpdateDeleteConfigurationCoordinate = (
    payload: any,
    type: Operations,
    id: string,
): Observable<any> => {
    let obs;
    switch (type) {
        case 'add':
            obs = sendRequestWithXSRFToken(buildCoordinatesUrl(), convertToSnake(payload), 'POST');
            break;
        case 'edit':
            obs = sendRequestWithXSRFToken(buildCoordinatesUrl(id), convertToSnake(payload), 'PUT');
            break;
        case 'remove':
            obs = sendDeleteRequestWithXSRFToken(buildCoordinatesUrl(id));
            break;
    }
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<GridEntry>) => {
            const res = convertToCamel<GridEntry>(response);
            return observableOf({ data: res, type });
        }),
    );
};
